import { useState, useEffect } from "react";
import { Col, OverlayTrigger, Tooltip } from "react-bootstrap";

import './CategoryToggle.css';


function CategoryToggle({ name, id, image_url, onClicked }) {
    const [checked, isChecked] = useState(false);

    let icon = ` ./${image_url}`;

    const className = checked ? "categoryToggle categoryChecked" : "categoryToggle";

    return (
        <OverlayTrigger placement='bottom'
            delay={{ show: 500, hide: 200 }}
            overlay={<Tooltip id="tooltip" trigger='hover'> {name[0] + name.substring(1).toLowerCase()} </Tooltip>}
        >
            <img
                src={icon}
                alt="not found"
                onClick={() => { isChecked(prev => !prev); onClicked({ checked, id }) }}
                className={className}
            />
        </OverlayTrigger>
    )
}

function CategorySelector({ possible_categories, onSelection, categories }) {
    const [selected_categories, setSelectedCategories] = useState(Array(possible_categories.length).fill(0));

    const handleClick = (item) => {
        const { id } = item;
        setSelectedCategories(prev => {
            const arr = [...prev];
            const index = categories[id];
            const val = prev[index];
            arr[index] = val === 0 ? 1 : 0;
            return arr;
        });
    };

    useEffect(() => {
        onSelection(selected_categories);
    }, [selected_categories, onSelection]);


    return (
        <>
            {possible_categories.map((cat, i) => {
                return (
                    <>
                        <Col>
                            <div key={cat}>
                                <CategoryToggle onClicked={handleClick} id={cat} name={cat} image_url={categories.imageUrls[i]} />
                            </div>
                        </Col>
                    </>
                );
            })}
        </>
    );
};


export default CategorySelector;