
import { SewingCategories, sewingSections } from "../../../SewingSectionsData";
import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import CategorySelector from '../../CategorySelector';
import Section from '../../Section';
import Footer from '../../Footer';
import Banner from "../../Banner";


export default function Naaisels() {
    return (
        <div style={{ backgroundColor: "#f2f5f7" }}>
            <Banner currentPage="Naaisels" />
            <MainPage />
            <Footer />
        </div>
    );
};


function Rows(filtered_sections) {

    if (filtered_sections.length === 0) {
        return (
            <>
                <Section>
                    <h3>Geen naaisels!</h3>
                </Section>
            </>
        );
    }

    let temp = [];
    let rows = [];

    const getColumn = (title, category, images, caption = null) => <Col md='6'>
        <Section
            key={title}
            title={`${SewingCategories.names[category]}: ${title}`}
            image_sources={images}
            image_caption={caption}
        />
    </Col>

    filtered_sections.forEach(({ title, category, images, caption }) => {
        if (temp.length === 0) {
            temp.push(getColumn(title, category, images, caption));
        }
        else {
            rows.push(
                <Row className="d-flex align-items-center justify-content-center">
                    {temp[0]}
                    {getColumn(title, category, images, caption)}
                </Row>
            )
            temp = [];
        }
    });

    if (temp.length) {
        rows.push(
            <Row>
                {temp}
            </Row>
        )
    }

    return (<>{rows}</>)
}


function MainPage() {
    const [selected_categories, setSelectedCategories] = useState([]);

    const is_filtered = selected_categories.reduce((total, n) => total + n, 0) > 0;

    const filtered_sections = is_filtered ?
        sewingSections.filter(section => {
            const index = section.category;
            return selected_categories[index] === 1;
        })
        : sewingSections;


    return (
        <>
            <Container className="text-center" >
                <Row>
                    <CategorySelector
                        categories={SewingCategories}
                        possible_categories={SewingCategories.names}
                        onSelection={new_selection => setSelectedCategories(new_selection)}
                    />
                </Row>
            </Container>
            <Container fluid='true'>
                {Rows(filtered_sections)}
            </Container>
        </>
    );
}
