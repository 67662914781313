import { useState } from 'react';
import { Route, Routes } from 'react-router';
import { Outlet, useNavigate } from 'react-router-dom';

import Page from './components/pages/Page';

import { Button, Container, Row, Col } from 'react-bootstrap';

import './custom.scss';
import './App.css';

import CategorySelector from './components/CategorySelector';
import Section from './components/Section';
import { bakingSections, BakingCategories } from './BakingSectionsData';
import Footer from './components/Footer';
import Naaisels from './components/pages/naaisels/Naaisels';
import Banner from './components/Banner';

function Main() {
    return (
        <>
            <Banner currentPage="Baksels" />
            <Outlet />
            <Footer />
        </>
    );
}

function Rows(filtered_sections) {

    if (filtered_sections.length === 0) {
        return (
            <>
                <Section>
                    <h3>Geen baksels!</h3>
                </Section>
            </>
        );
    }

    let temp = [];
    let rows = [];

    const getColumn = (title, category, images, caption = null) => <Col md='6'>
        <Section
            key={title}
            title={`${BakingCategories.names[category]}: ${title}`}
            image_sources={images}
            image_caption={caption}
        />
    </Col>

    filtered_sections.forEach(({ title, category, images, caption }) => {
        if (temp.length === 0) {
            temp.push(getColumn(title, category, images, caption));
        }
        else {
            rows.push(
                <Row className="d-flex align-items-center justify-content-center">
                    {temp[0]}
                    {getColumn(title, category, images, caption)}
                </Row>
            )
            temp = [];
        }
    });

    if (temp.length) {
        rows.push(
            <Row>
                {temp}
            </Row>
        )
    }

    return (<>{rows}</>)
}


function MainPage() {
    const [selected_categories, setSelectedCategories] = useState([]);

    const is_filtered = selected_categories.reduce((total, n) => total + n, 0) > 0;

    const filtered_sections = is_filtered ?
        bakingSections.filter(section => {
            const index = section.category;
            return selected_categories[index] === 1;
        })
        : bakingSections;


    return (
        <>
            <Container className="text-center" >
                <Row>
                    <CategorySelector
                        possible_categories={BakingCategories.names}
                        onSelection={new_selection => setSelectedCategories(new_selection)}
                        categories={BakingCategories}
                    />
                </Row>
            </Container>
            <Container fluid='true'>
                {Rows(filtered_sections)}
            </Container>
        </>
    );
}


function NotFound() {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/");
    };

    return (
        <Page header="Pagina bestaat niet...">
            <Button onClick={handleClick}>Ga naar de hoofdpagina.</Button>
        </Page>
    );
}


function App() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Main />}>
                    <Route path="" element={<MainPage />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
                <Route path="/naaisels" >
                    <Route path="" element={<Naaisels />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </div>
    );
}


export default App;
