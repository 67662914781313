

export default function Footer() {
    const css = {
        minHeight: '120px',
        padding: '40px'
    };
    const d = new Date();
    return(
        <div style={css}>
            <p style={{textAlign: 'center', color:'#777'}}><small>Copyright Emma van Oppen &copy; {d.getFullYear()}</small></p>
        </div>
    );
}