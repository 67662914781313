

export default function Page({header, children}) {
    const css = {
        margin: '20px'
    };
    return <div style={css}>
        <h2>{header}</h2>
        {children}
    </div>
}