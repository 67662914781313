
export const SewingCategories = {
    GEEN: -1,
    JURKEN: 0,
    TOPS: 1,
    BROEKEN: 2,
    ROKKEN: 3,
    JASSEN: 4,
    LOUNGEKLEDING: 5,
    ACCESSOIRES: 6,
    TASSEN: 7,
    HUISTEXTIEL: 8,
    OVERIG: 9,

    names: [
        "JURKEN",
        "TOPS",
        "BROEKEN",
        "ROKKEN",
        "JASSEN",
        "LOUNGEKLEDING",
        "ACCESSOIRES",
        "TASSEN",
        "HUISTEXTIEL",
        "OVERIG",
    ],

    imageUrls: [
        "svg/nav_jurken.png",
        'svg/nav_tops.png',
        'svg/nav_broeken.png',
        'svg/nav_rokken.png',
        'svg/nav_jassen.png',
        "svg/nav_zwemkleding.png",
        'svg/nav_accessoires.png',
        "svg/nav_zwemkleding.png",
        "svg/nav_zwemkleding.png",
        "svg/nav_zwemkleding.png",
    ]
};

export const sewingSections = [
    {
        category: SewingCategories.JURKEN, title: "Trouwgast Jurk 1", images: [
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk1.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk2.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk3.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk4.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk5.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk6.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk7.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk8.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk9.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk10.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk11.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk12.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk13.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk14.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk14b.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk15.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk15b.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk16.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk17.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk18.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk19.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk20.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk21.jpg',
            './img/naaisels/jurken/bruiloft-jurk-1/bruiloftjurk22.jpg',
        ], caption: {
            title: `96% viscose, 4% elastaan`
        }
    },
    {
        category: SewingCategories.JURKEN, title: "Trouwgast Jurk 2", images: [
            './img/naaisels/jurken/bruiloft-jurk-2/composition.jpg',
            './img/naaisels/jurken/bruiloft-jurk-2/1.jpg',
            './img/naaisels/jurken/bruiloft-jurk-2/2.jpg',
            './img/naaisels/jurken/bruiloft-jurk-2/3.jpg',
            './img/naaisels/jurken/bruiloft-jurk-2/4.jpg',
            './img/naaisels/jurken/bruiloft-jurk-2/5.jpg',
            './img/naaisels/jurken/bruiloft-jurk-2/6.jpg',
            './img/naaisels/jurken/bruiloft-jurk-2/7.jpg',
            './img/naaisels/jurken/bruiloft-jurk-2/8.jpg',
        ], caption: {
            title: `95% katoen, 5% elastaan`
        }
    },
    {
        category: SewingCategories.JASSEN, title: "Winterjas wol donker navy", images: [
            './img/naaisels/jassen/wool-winter-coat-1/0a.jpg',
            './img/naaisels/jassen/wool-winter-coat-1/0b.jpg',
            './img/naaisels/jassen/wool-winter-coat-1/1.jpg',
            './img/naaisels/jassen/wool-winter-coat-1/2.jpg',
            './img/naaisels/jassen/wool-winter-coat-1/3.jpg',
            './img/naaisels/jassen/wool-winter-coat-1/4.jpg',
            './img/naaisels/jassen/wool-winter-coat-1/5.jpg',
            './img/naaisels/jassen/wool-winter-coat-1/6.jpg',
            './img/naaisels/jassen/wool-winter-coat-1/7.jpg',
            './img/naaisels/jassen/wool-winter-coat-1/8.jpg',
            './img/naaisels/jassen/wool-winter-coat-1/9.jpg',
            './img/naaisels/jassen/wool-winter-coat-1/10.jpg',
            './img/naaisels/jassen/wool-winter-coat-1/11.jpg',
            './img/naaisels/jassen/wool-winter-coat-1/12.jpg',
            './img/naaisels/jassen/wool-winter-coat-1/13.jpg',
        ], caption: {
            title: `Buitenlaag: 95% katoen, 5% elastaan; Voering: 45% viscose, 55% polyester`
        }
    },
    {
        category: SewingCategories.HUISTEXTIEL, title: "Pannenlappen", images: [
            './img/naaisels/huistextiel/pannenlappen-1/1.jpg',
            './img/naaisels/huistextiel/pannenlappen-1/2.jpg',
            './img/naaisels/huistextiel/pannenlappen-1/3.jpg',
            './img/naaisels/huistextiel/pannenlappen-1/4.jpg',
            './img/naaisels/huistextiel/pannenlappen-1/5.jpg',
            './img/naaisels/huistextiel/pannenlappen-1/6.jpg',
        ], caption: {
            title: `Buitenlaag: 85% katoen, 15% linnen; Tussenvoering: 100% wol`
        }
    },
    {
        category: SewingCategories.JURKEN, title: "Kaftan", images: [
            './img/naaisels/jurken/kaftan-1/0a.jpg',
            './img/naaisels/jurken/kaftan-1/0b.jpg',
            './img/naaisels/jurken/kaftan-1/1.jpg',
            './img/naaisels/jurken/kaftan-1/2.jpg',
            './img/naaisels/jurken/kaftan-1/3.jpg',
            './img/naaisels/jurken/kaftan-1/4.jpg',
            './img/naaisels/jurken/kaftan-1/5.jpg',
            './img/naaisels/jurken/kaftan-1/6.jpg',
            './img/naaisels/jurken/kaftan-1/7.jpg',
        ], caption: {
            title: `100% viscose`
        }
    },
    {
        category: SewingCategories.TASSEN, title: "Haaknaalden Etui", images: [
            './img/naaisels/tassen/crochet-hook-pouch-1/1.jpg',
            './img/naaisels/tassen/crochet-hook-pouch-1/2.jpg',
            './img/naaisels/tassen/crochet-hook-pouch-1/3.jpg',
            './img/naaisels/tassen/crochet-hook-pouch-1/4.jpg',
        ], caption: {
            title: `100% katoen`
        }
    },
    {
        category: SewingCategories.LOUNGEKLEDING, title: "Kamerjas", images: [
            './img/naaisels/loungekleding/kamerjas-1/1.jpg',
            './img/naaisels/loungekleding/kamerjas-1/2.jpg',
            './img/naaisels/loungekleding/kamerjas-1/3.jpg',
            './img/naaisels/loungekleding/kamerjas-1/4.jpg',
            './img/naaisels/loungekleding/kamerjas-1/5.jpg',
            './img/naaisels/loungekleding/kamerjas-1/6.jpg',
        ], caption: {
            title: `100% katoen`
        }
    },
    {
        category: SewingCategories.ACCESSOIRES, title: "Pochette 1", images: [
            './img/naaisels/accessoires/pocket-square-1/1.jpg',
            './img/naaisels/accessoires/pocket-square-1/2.jpg',
            './img/naaisels/accessoires/pocket-square-1/3.jpg',
            './img/naaisels/accessoires/pocket-square-1/4.jpg',
        ], caption: {
            title: `100% katoen`
        }
    },
    {
        category: SewingCategories.ACCESSOIRES, title: "Pochette 2", images: [
            './img/naaisels/accessoires/pocket-square-2/1.jpg',
            './img/naaisels/accessoires/pocket-square-2/2.jpg',
            './img/naaisels/accessoires/pocket-square-2/3.jpg',
        ], caption: {
            title: `55% katoen, 45% viscose`
        }
    },
    {
        category: SewingCategories.LOUNGEKLEDING, title: "Yukata", images: [
            './img/naaisels/loungekleding/yukata-1/1.jpg',
            './img/naaisels/loungekleding/yukata-1/2.jpg',
            './img/naaisels/loungekleding/yukata-1/3.jpg',
            './img/naaisels/loungekleding/yukata-1/4.jpg',
            './img/naaisels/loungekleding/yukata-1/5.jpg',
        ], caption: {
            title: `100% katoen`
        }
    },
];