import { useCallback, useState } from "react";
import { useNavigate } from "react-router";

export const MenuItems = [
    { name: "Baksels", href: "/" },
    { name: "Naaisels", href: "/naaisels" },
]

export default function Banner({ currentPage }) {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const onClick = useCallback((href) => {
        navigate(href);
    }, [navigate]);

    return (
        <div className="text-center" id={currentPage === "Naaisels" ? "sewbanner" : "banner"}>
            <div id='bannerText'>
                <h1>Emma's&nbsp;</h1>
                <div id="banner-dropdown-container" style={{ position: "relative" }}>
                    <button id="dropdown-basic-button" onClick={() => setDropdownOpen(!dropdownOpen)}>{currentPage}</button>

                    <div className='dropdownMenu' style={{ display: dropdownOpen ? "block" : "none" }}>
                        {MenuItems.filter((i) => i.name !== currentPage).map((i) => <div className="dropdownItem" key={i.href} onClick={() => onClick(i.href)}>{i.name}</div>)}
                    </div>

                </div>
            </div>
        </div>
    );
}