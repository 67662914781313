
export const BakingCategories = {
    GEEN: -1,
    KOEKJES: 0,
    CAKES: 1,
    TAARTEN: 2,
    GEBAK: 3,
    DESSERTS: 4,
    BROOD: 5,

    names: [
        "KOEKJES",
        "CAKES",
        "TAARTEN",
        "GEBAK",
        "DESSERTS",
        "BROOD"
    ],

    imageUrls: [
        "svg/nav_koekjes.svg",
        'svg/nav_cake.svg',
        'svg/nav_taarten.svg',
        'svg/nav_gebak.svg',
        'svg/nav_dessert.svg',
        'svg/nav_brood.svg',
    ]
};

export const bakingSections = [
    {
        category: BakingCategories.TAARTEN, title: "Le Gateau Vert Bruidstaart", images: [
            './img/baksels/taarten/le-gateau-vert-2/gateauvert1.jpg',
            './img/baksels/taarten/le-gateau-vert-2/gateauvert2.jpg',
            './img/baksels/taarten/le-gateau-vert-2/gateauvert3.jpg'
        ], caption: {
            title: `Foto's gemaakt door Siem van Woerkom`
        }
    },
    {
        category: BakingCategories.TAARTEN, title: "Citroen Bruidstaart", images: [
            './img/baksels/taarten/citroen-bruidstaart-1/bruidstaart-1.jpg',
            './img/baksels/taarten/citroen-bruidstaart-1/bruidstaart-2.jpg',
            './img/baksels/taarten/citroen-bruidstaart-1/bruidstaart-3.jpg',
            './img/baksels/taarten/citroen-bruidstaart-1/bruidstaart-4.jpg'
        ], caption: {
            title: `Foto's gemaakt door Lisan Spiegelaar`,
            hyperlinks: [
                { link: 'https://lisanspiegelaar.nl/', text: 'lisanspiegelaar.nl' },
                { link: 'https://www.instagram.com/lisanspiegelaarfotografie/', text: '@lisanspiegelaarfotografie' }
            ]
        }
    },
    {
        category: BakingCategories.DESSERTS, title: "Frambozenijs", images: [
            './img/baksels/desserts/frambozen-roomijs-1/frambozenijs-1.jpg',
            './img/baksels/desserts/frambozen-roomijs-1/frambozenijs-2.jpg'
        ]
    },
    {
        category: BakingCategories.KOEKJES, title: "Lace Tuile", images: [
            './img/baksels/koekjes/lace-tuile-1/tuile-1.jpg',
            './img/baksels/koekjes/lace-tuile-1/tuile-2.jpg',
            './img/baksels/koekjes/lace-tuile-1/tuile-3.jpg',
            './img/baksels/koekjes/lace-tuile-1/tuile-4.jpg'
        ]
    },
    {
        category: BakingCategories.DESSERTS, title: "Amandelmousse met Stoofpeerijs en Speculaas", images: [
            './img/baksels/desserts/stoofpeer-amandel-dessert-1/mousse-stoofpeer-1.jpg',
            './img/baksels/desserts/stoofpeer-amandel-dessert-1/mousse-stoofpeer-2.jpg',
            './img/baksels/desserts/stoofpeer-amandel-dessert-1/mousse-stoofpeer-3.jpg',
            './img/baksels/desserts/stoofpeer-amandel-dessert-1/mousse-stoofpeer-4.jpg',
            './img/baksels/desserts/stoofpeer-amandel-dessert-1/mousse-stoofpeer-5.jpg',
            './img/baksels/desserts/stoofpeer-amandel-dessert-1/mousse-stoofpeer-6.jpg'
        ]
    },
    {
        category: BakingCategories.KOEKJES, title: "Kokosmakronen", images: [
            './img/baksels/koekjes/kokosmakronen-1/kokosmakronen-1.jpg',
            './img/baksels/koekjes/kokosmakronen-1/kokosmakronen-2.jpg',
            './img/baksels/koekjes/kokosmakronen-1/kokosmakronen-3.jpg'
        ]
    },
    {
        category: BakingCategories.TAARTEN, title: "Sinaasappel Speculaas Cheesecake 2", images: [
            './img/baksels/taarten/sinaasappel-speculaas-cheesecake-2/sinaasappel-cheesecake-1.jpg',
            './img/baksels/taarten/sinaasappel-speculaas-cheesecake-2/sinaasappel-cheesecake-2.jpg',
            './img/baksels/taarten/sinaasappel-speculaas-cheesecake-2/sinaasappel-cheesecake-3.jpg',
            './img/baksels/taarten/sinaasappel-speculaas-cheesecake-2/sinaasappel-cheesecake-4.jpg',
            './img/baksels/taarten/sinaasappel-speculaas-cheesecake-2/sinaasappel-cheesecake-5.jpg',
            './img/baksels/taarten/sinaasappel-speculaas-cheesecake-2/sinaasappel-cheesecake-10.jpg'
        ]
    },
    {
        category: BakingCategories.KOEKJES, title: "Citroen Macarons", images: [
            './img/baksels/koekjes/macarons-citroen-1/citroen-macaron-1.jpg',
        ]
    },
    {
        category: BakingCategories.KOEKJES, title: "Pepernoten", images: [
            './img/baksels/koekjes/pepernoten-1/pepernoten-1.jpg',
            './img/baksels/koekjes/pepernoten-1/pepernoten-2.jpg',
            './img/baksels/koekjes/pepernoten-1/pepernoten-3.jpg',
            './img/baksels/koekjes/pepernoten-1/pepernoten-4.jpg'
        ]
    },
    {
        category: BakingCategories.DESSERTS, title: "Stoofperen Sorbet", images: [
            './img/baksels/desserts/stoofperen-sorbet-1/stoofperen-sorbet-1.jpg',
            './img/baksels/desserts/stoofperen-sorbet-1/stoofperen-sorbet-2.jpg',
            './img/baksels/desserts/stoofperen-sorbet-1/stoofperen-sorbet-3.jpg'
        ]
    },
    {
        category: BakingCategories.KOEKJES, title: "Kruidnoten", images: [
            './img/baksels/koekjes/kruidnoten-1/kruidnoten-1.jpg',
            './img/baksels/koekjes/kruidnoten-1/kruidnoten-2.jpg',
            './img/baksels/koekjes/kruidnoten-1/kruidnoten-3.jpg'
        ]
    },
    {
        category: BakingCategories.DESSERTS, title: "Amandelmousse", images: [
            './img/baksels/desserts/amandel-mousse-1/amandel-mousse-1.jpg',
            './img/baksels/desserts/amandel-mousse-1/amandel-mousse-2.jpg',
            './img/baksels/desserts/amandel-mousse-1/amandel-mousse-3.jpg',
            './img/baksels/desserts/amandel-mousse-1/amandel-mousse-4.jpg',
            './img/baksels/desserts/amandel-mousse-1/amandel-mousse-5.jpg'
        ]
    },
    {
        category: BakingCategories.DESSERTS, title: "Pistachemousse", images: [
            './img/baksels/desserts/pistache-mousse-1/pistache-mousse-1.jpg',
            './img/baksels/desserts/pistache-mousse-1/pistache-mousse-2.jpg',
            './img/baksels/desserts/pistache-mousse-1/pistache-mousse-3.jpg',
            './img/baksels/desserts/pistache-mousse-1/pistache-mousse-4.jpg'
        ]
    },
    {
        category: BakingCategories.KOEKJES, title: "Ginger Snaps", images: [
            './img/baksels/koekjes/ginger-snaps-1/ginger-snap-1.jpg',
            './img/baksels/koekjes/ginger-snaps-1/ginger-snap-2.jpg',
            './img/baksels/koekjes/ginger-snaps-1/ginger-snap-3.jpg',
            './img/baksels/koekjes/ginger-snaps-1/ginger-snap-4.jpg',
            './img/baksels/koekjes/ginger-snaps-1/ginger-snap-5.jpg'
        ]
    },
    {
        category: BakingCategories.DESSERTS, title: "Tiramisu", images: [
            './img/baksels/desserts/tiramisu-1/tiramisu2.jpg',
            './img/baksels/desserts/tiramisu-1/tiramisu1.jpg'
        ]
    },
    {
        category: BakingCategories.BROOD, title: "Scones", images: [
            './img/baksels/brood/scones-1/scones1.jpg',
            './img/baksels/brood/scones-1/scones2.jpg',
            './img/baksels/brood/scones-1/scones3.jpg',
            './img/baksels/brood/scones-1/scones4.jpg',
            './img/baksels/brood/scones-1/scones5.jpg',
            './img/baksels/brood/scones-1/scones6.jpg',
            './img/baksels/brood/scones-1/scones7.jpg',
        ]
    },
    {
        category: BakingCategories.GEBAK, title: "Choux au craquelin", images: [
            './img/baksels/gebak/choux-au-craquelin-1/chouxcraq1.jpg',
            './img/baksels/gebak/choux-au-craquelin-1/chouxcraq2.jpg',
            './img/baksels/gebak/choux-au-craquelin-1/chouxcraq3.jpg',
            './img/baksels/gebak/choux-au-craquelin-1/chouxcraq4.jpg',
            './img/baksels/gebak/choux-au-craquelin-1/chouxcraq5.jpg',
            './img/baksels/gebak/choux-au-craquelin-1/chouxcraq6.jpg',
            './img/baksels/gebak/choux-au-craquelin-1/chouxcraq7.jpg'
        ]
    },
    {
        category: BakingCategories.DESSERTS, title: "Kokosmousse met mangocoulis", images: [
            './img/baksels/desserts/kokosmousse-1/kokosmousse1.jpg',
            './img/baksels/desserts/kokosmousse-1/kokosmousse2.jpg',
            './img/baksels/desserts/kokosmousse-1/kokosmousse3.jpg',
            './img/baksels/desserts/kokosmousse-1/kokosmousse4.jpg'
        ]
    },
    {
        category: BakingCategories.GEBAK, title: "Mini lemon meringue taartjes", images: [
            './img/baksels/gebak/lemon-meringue-1/lemonmeringue1.jpg',
            './img/baksels/gebak/lemon-meringue-1/lemonmeringue2.jpg',
            './img/baksels/gebak/lemon-meringue-1/lemonmeringue3.jpg',
            './img/baksels/gebak/lemon-meringue-1/lemonmeringue4.jpg',
            './img/baksels/gebak/lemon-meringue-1/lemonmeringue5.jpg',
            './img/baksels/gebak/lemon-meringue-1/lemonmeringue6.jpg',
            './img/baksels/gebak/lemon-meringue-1/lemonmeringue7.jpg'
        ]
    },
    {
        category: BakingCategories.DESSERTS, title: "Crème Brûlée", images: [
            './img/baksels/desserts/creme-brulee-1/cremebrulee1.jpg',
            './img/baksels/desserts/creme-brulee-1/cremebrulee2.jpg',
            './img/baksels/desserts/creme-brulee-1/cremebrulee3.jpg',
            './img/baksels/desserts/creme-brulee-1/cremebrulee4.jpg'
        ]
    },
    {
        category: BakingCategories.KOEKJES, title: "Fudge", images: [
            './img/baksels/koekjes/fudge-1/fudge1.jpg',
            './img/baksels/koekjes/fudge-1/fudge2.jpg',
            './img/baksels/koekjes/fudge-1/fudge3.jpg',
            './img/baksels/koekjes/fudge-1/fudge4.jpg'
        ]
    },
    {
        category: BakingCategories.BROOD, title: "Pita", images: [
            './img/baksels/brood/pita-1/pita2.jpg',
            './img/baksels/brood/pita-1/pita1.jpg',
            './img/baksels/brood/pita-1/pita3.jpg',
            './img/baksels/brood/pita-1/pita4.jpg'
        ]
    },
    {
        category: BakingCategories.CAKES, title: "Spekkoek", images: [
            './img/baksels/cakes/spekkoek-1/spekkoek1.jpg',
            './img/baksels/cakes/spekkoek-1/spekkoek2.jpg',
            './img/baksels/cakes/spekkoek-1/spekkoek3.jpg',
            './img/baksels/cakes/spekkoek-1/spekkoek4.jpg',
            './img/baksels/cakes/spekkoek-1/spekkoek5.jpg',
            './img/baksels/cakes/spekkoek-1/spekkoek6.jpg',
        ]
    },
    {
        category: BakingCategories.BROOD, title: "Worstenbrood", images: [
            './img/baksels/brood/worstenbrood-1/worstenbrood1.jpg',
            './img/baksels/brood/worstenbrood-1/worstenbrood2.jpg',
            './img/baksels/brood/worstenbrood-1/worstenbrood3.jpg',
            './img/baksels/brood/worstenbrood-1/worstenbrood4.jpg',
        ]
    },
    {
        category: BakingCategories.KOEKJES, title: "Chocolate Chip Cookies", images: [
            './img/baksels/koekjes/chocolate-chip-cookies-1/chocchipcookies1.jpg',
            './img/baksels/koekjes/chocolate-chip-cookies-1/chocchipcookies2.jpg',
            './img/baksels/koekjes/chocolate-chip-cookies-1/chocchipcookies3.jpg',
            './img/baksels/koekjes/chocolate-chip-cookies-1/chocchipcookies4.jpg',
        ]
    },
    {
        category: BakingCategories.KOEKJES, title: "Macarons", images: [
            './img/baksels/koekjes/macarons-pistache-1/macarons1.jpg',
            './img/baksels/koekjes/macarons-pistache-1/macarons2.jpg',
            './img/baksels/koekjes/macarons-pistache-1/macarons3.jpg',
            './img/baksels/koekjes/macarons-pistache-1/macarons4.jpg',
            './img/baksels/koekjes/macarons-pistache-1/macarons5.jpg',
            './img/baksels/koekjes/macarons-pistache-1/macarons6.jpg'
        ]
    },
    {
        category: BakingCategories.TAARTEN, title: "Le Gateau Vert", images: [
            './img/baksels/taarten/le-gateau-vert-1/gateauvert1.jpg',
            './img/baksels/taarten/le-gateau-vert-1/gateauvert2.jpg',
            './img/baksels/taarten/le-gateau-vert-1/gateauvert3.jpg'
        ]
    },
    {
        category: BakingCategories.GEBAK, title: "Donuts", images: [
            './img/baksels/gebak/donuts-suikerglazuur-1/donuts1.jpg',
            './img/baksels/gebak/donuts-suikerglazuur-1/donuts2.jpg',
            './img/baksels/gebak/donuts-suikerglazuur-1/donuts3.jpg',
            './img/baksels/gebak/donuts-suikerglazuur-1/donuts4.jpg'
        ]
    },
    {
        category: BakingCategories.TAARTEN, title: "Bosbessen Citroentaart Babyshower", images: [
            './img/baksels/taarten/bosbessen-citroentaart-2/bloemenbbtaart1.jpg',
            './img/baksels/taarten/bosbessen-citroentaart-2/bloemenbbtaart2.jpg',
            './img/baksels/taarten/bosbessen-citroentaart-2/bloemenbbtaart3.jpg',
            './img/baksels/taarten/bosbessen-citroentaart-2/bloemenbbtaart4.jpg'
        ]
    },
    {
        category: BakingCategories.GEBAK, title: "Eclairs", images: [
            './img/baksels/gebak/eclairs-klassiek-1/eclairs1.jpg',
            './img/baksels/gebak/eclairs-klassiek-1/eclairs2.jpg',
            './img/baksels/gebak/eclairs-klassiek-1/eclairs3.jpg'
        ]
    },
    {
        category: BakingCategories.KOEKJES, title: "Cantuccini's", images: [
            './img/baksels/koekjes/cantuccini-amandel-1/cantuccini1.jpg'
        ]
    },
    {
        category: BakingCategories.BROOD, title: "Challah", images: [
            './img/baksels/brood/challah-vier-strengen-1/challah1.jpg',
            './img/baksels/brood/challah-vier-strengen-1/challah2.jpg',
            './img/baksels/brood/challah-vier-strengen-1/challah3.jpg'
        ]
    },
    {
        category: BakingCategories.TAARTEN, title: "Sinaasappel Speculaas Cheesecake", images: [
            './img/baksels/taarten/sinaasappel-speculaas-cheesecake-1/sinaasappelspeculaas1.jpg',
            './img/baksels/taarten/sinaasappel-speculaas-cheesecake-1/sinaasappelspeculaas2.jpg',
            './img/baksels/taarten/sinaasappel-speculaas-cheesecake-1/sinaasappelspeculaas3.jpg',
            './img/baksels/taarten/sinaasappel-speculaas-cheesecake-1/sinaasappelspeculaas4.jpg',
        ]
    },
    {
        category: BakingCategories.TAARTEN, title: "Kokos Carameltaart", images: [
            './img/baksels/taarten/kokos-meringue-taart-1/kokostaart1.jpg',
            './img/baksels/taarten/kokos-meringue-taart-1/kokostaart2.jpg'
        ]
    },
    {
        category: BakingCategories.BROOD, title: "Croissants", images: [
            './img/baksels/brood/croissants-1/croissant1.jpg',
            './img/baksels/brood/croissants-1/croissant2.jpg',
            './img/baksels/brood/croissants-1/croissant3.jpg',
            './img/baksels/brood/croissants-1/croissant4.jpg',
            './img/baksels/brood/croissants-1/croissant5.jpg',
            './img/baksels/brood/croissants-1/croissant6.jpg'
        ]
    },
    {
        category: BakingCategories.BROOD, title: "Bagels", images: [
            './img/baksels/brood/bagels-1/bagels1.jpg',
            './img/baksels/brood/bagels-1/bagels2.jpg'
        ]
    },
    {
        category: BakingCategories.KOEKJES, title: "Spritsen", images: [
            './img/baksels/koekjes/spritsen-1/spritsen1.jpg',
            './img/baksels/koekjes/spritsen-1/spritsen2.jpg',
            './img/baksels/koekjes/spritsen-1/spritsen3.jpg',
            './img/baksels/koekjes/spritsen-1/spritsen4.jpg'
        ]
    },
    {
        category: BakingCategories.BROOD, title: "Bapao", images: [
            './img/baksels/brood/bapao-gehaktvulling-1/bapao1.jpg',
            './img/baksels/brood/bapao-gehaktvulling-1/bapao2.jpg',
            './img/baksels/brood/bapao-gehaktvulling-1/bapao3.jpg'
        ]
    },
    {
        category: BakingCategories.BROOD, title: "Bieslook Bolletjes", images: [
            './img/baksels/brood/bolletjes-zure-room-bieslook-1/bolletjes1.jpg',
            './img/baksels/brood/bolletjes-zure-room-bieslook-1/bolletjes2.jpg'
        ]
    },
    {
        category: BakingCategories.BROOD, title: "Brioche", images: [
            './img/baksels/brood/brioche-1/brioche1.jpg',
            './img/baksels/brood/brioche-1/brioche2.jpg',
            './img/baksels/brood/brioche-1/brioche3.jpg'
        ]
    },
    {
        category: BakingCategories.BROOD, title: "Bruin Busbrood", images: [
            './img/baksels/brood/busbrood-bruin-1/busbrood1.jpg',
            './img/baksels/brood/busbrood-bruin-1/busbrood2.jpg',
            './img/baksels/brood/busbrood-bruin-1/busbrood3.jpg',
            './img/baksels/brood/busbrood-bruin-1/busbrood4.jpg'
        ]
    },
    {
        category: BakingCategories.BROOD, title: "Ciabatta", images: [
            './img/baksels/brood/ciabatta-1/ciabatta1.jpg',
            './img/baksels/brood/ciabatta-1/ciabatta2.jpg'
        ]
    },
    {
        category: BakingCategories.CAKES, title: "Citroen Tulbandcake", images: [
            './img/baksels/cakes/citroencake-tulband-1/tulband1.jpg',
            './img/baksels/cakes/citroencake-tulband-1/tulband2.jpg',
            './img/baksels/cakes/citroencake-tulband-1/tulband3.jpg'
        ]
    },
    {
        category: BakingCategories.DESSERTS, title: "Chocolade Souffle", images: [
            './img/baksels/desserts/chocolade-souffle-1/chocoladesouffle1.jpg',
            './img/baksels/desserts/chocolade-souffle-1/chocoladesouffle2.jpg',
            './img/baksels/desserts/chocolade-souffle-1/chocoladesouffle3.jpg'
        ]
    },
    {
        category: BakingCategories.GEBAK, title: "Baklava", images: [
            './img/baksels/gebak/baklava-pistache-1/baklava1.jpg',
            './img/baksels/gebak/baklava-pistache-1/baklava2.jpg'
        ]
    },
    {
        category: BakingCategories.GEBAK, title: "Brownies", images: [
            './img/baksels/gebak/brownies-chocolade-1/brownies1.jpg',
            './img/baksels/gebak/brownies-chocolade-1/brownies2.jpg',
            './img/baksels/gebak/brownies-chocolade-1/brownies3.jpg',
            './img/baksels/gebak/brownies-chocolade-1/brownies4.jpg'
        ]
    },
    {
        category: BakingCategories.GEBAK, title: "Opera Cake", images: [
            './img/baksels/gebak/opera-cake-1/operacake1.jpg'
        ]
    },
    {
        category: BakingCategories.TAARTEN, title: "Lemon Meringue Pie", images: [
            './img/baksels/taarten/lemon-meringue-pie-1/lemonmeringue1.jpg',
            './img/baksels/taarten/lemon-meringue-pie-1/lemonmeringue2.jpg',
            './img/baksels/taarten/lemon-meringue-pie-1/lemonmeringue3.jpg'
        ]
    },
    {
        category: BakingCategories.TAARTEN, title: "Bosbessen Citroentaart", images: [
            './img/baksels/taarten/bosbessen-citroentaart-1/bbtaart1.jpg',
            './img/baksels/taarten/bosbessen-citroentaart-1/bbtaart2.jpg',
            './img/baksels/taarten/bosbessen-citroentaart-1/bbtaart3.jpg'
        ]
    },
    {
        category: BakingCategories.TAARTEN, title: "Bramen Charlotte Russe", images: [
            './img/baksels/taarten/charlotte-russe-braam-1/bramencharlotte1.jpg',
            './img/baksels/taarten/charlotte-russe-braam-1/bramencharlotte2.jpg'
        ]
    },
    {
        category: BakingCategories.TAARTEN, title: "Synthesizer Taart", images: [
            './img/baksels/taarten/taart-synthesizer-1/synthtaart1.jpg',
            './img/baksels/taarten/taart-synthesizer-1/synthtaart2.jpg',
            './img/baksels/taarten/taart-synthesizer-1/synthtaart4.jpg',
            './img/baksels/taarten/taart-synthesizer-1/synthtaart5.jpg',
            './img/baksels/taarten/taart-synthesizer-1/synthtaart6.jpg',
            './img/baksels/taarten/taart-synthesizer-1/synthtaart7.jpg'
        ]
    },
];