import { Carousel, Container } from 'react-bootstrap';
import './Section.css'

export default function Section({ image_sources = [], image_caption = {} }) {
    const multipleImages = image_sources.length > 1;

    const getImage = (image_src) => <img alt="" className="Image" src={image_src}></img>;

    const getCarouselCaption = (caption) =>
        <div className="carousel-caption-container">
            <h5 className="caption-title">{caption.title}</h5>
            <span className="hyperlink-container">
                {caption.hyperlinks?.map((hyperlink) =>
                    <a key={hyperlink.link} href={hyperlink.link} target="_blank" rel="noopener noreferrer">{hyperlink.text}</a>
                )}
            </span>
        </div>

    const getCarouselItem = (image_src) =>
        <Carousel.Item key={image_src}>
            <Container fluid style={{ aspectRatio: '1/1' }}>
                {getImage(image_src)}
            </Container>
        </Carousel.Item>

    if (image_sources.length === 0) {
        return (
            <div className="Section" >
                <p>Geen afbeeldingen gevonden.</p>
            </div>
        )
    }

    return (
        <div className="Section" >
            {
                <Carousel interval={null} controls={multipleImages} indicators={multipleImages}>
                    {image_sources.map((image_src) => getCarouselItem(image_src))}
                </Carousel>
            }
            {image_caption ? getCarouselCaption(image_caption) : null}
        </div>
    );
};